import { DataFieldInputProps, Form, SubmissionError } from 'dataformjs';
import { navigate } from 'gatsby';
import React, { FC } from 'react';

import App from '../layout/App';

const Login: FC = () => {
  const handleOnSubmit = ({ password }: { password: string }) => {
    let isLogged = false;
    if (process.env.GATSBY_ACCESS_PASSWORD === password) {
      isLogged = true;
    }

    if (!isLogged) {
      throw new SubmissionError({
        _error: 'login/form/error/incorrect-password',
      });
    } else {
      localStorage.setItem('password', password);
      navigate('/');
    }
  };

  const classnames =
    'bg-light-300 p-12 shadow-sm w-full md:w-1/2 rounded-b-md flex flex-col items-center justify-center space-y-6';

  return (
    <App isLogged={false}>
      <div className="flex items-center justify-center">
        <Form
          bodyProps={{
            className: 'w-full flex flex-col space-y-6 items-center',
          }}
          className={classnames}
          datas={
            {
              componentType: 'input',
              fieldProps: {
                className: 'w-full border border-light-600 p-2',
              },
              label: 'Mot de passe',
              labelShow: false,
              name: 'password',
              placeholder: 'Mot de passe',
              required: true,
              wrapperProps: {
                className: 'flex-1 w-full',
              },
            } as DataFieldInputProps
          }
          name="login"
          onSubmit={handleOnSubmit}
          submitProps={{
            className: 'bg-primary-500 py-2 px-4 text-light-50',
            label: "Accéder à l'API",
          }}
        />
      </div>
    </App>
  );
};

export default Login;
